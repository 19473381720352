import React from "react";
import "./styles/page.css";
import "./styles/main.css";
import "./styles/sidebar.css";
import "./styles/header.css";
import "./styles/workspace.css";
import "./styles/dashboard.css";
import { ReactComponent as IconHome } from "./icons/home.svg";
import { ReactComponent as IconVoucher } from "./icons/voucher.svg";
import { ReactComponent as IconAnalytics } from "./icons/analytics.svg";
import { ReactComponent as IconEdit } from "./icons/edit.svg";
import { ReactComponent as IconUserManagement } from "./icons/Admin.svg";
import { ReactComponent as IconAppDownload } from "./icons/app-download.svg";
import { ReactComponent as IconHelp } from "./icons/help.svg";
import { ReactComponent as IconLogout } from "./icons/logout.svg";
import TopMenuIcon from "./images/meed logo-cream on grey.svg";
import { ReactComponent as PremiumIndicator } from "../../../assets/icons/premium-crown.svg";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { persist } from "../../../store"; // Import persist from where you have defined it
import logoutService from "../../../services/authService"; // Import the logout service
import * as actions from "../../../actions/actions";
import * as selectors from "../../../selectors/selectors";
import * as selector from "../../../selectors/selectors";
import * as constants from "../../../utils/constants";
import useGetVouchers from "../../../Hooks/getVouchers";
import useGetPrograms from "../../../Hooks/getPrograms";
import useGetVerified from "../../../Hooks/getVerified";
import useLimitCheck from "../../../Hooks/getLimitCheck";
import { getScrollbarWidth } from "../../../utils/helpers";
import {
  handleActionNotification,
  useShowTierLimitReachedWarning,
} from "../../../utils/utils";
import User from "../../../Components/User/user";
import AddProgramBar from "../../../Components/AddProgramBar/add_program_bar";
import ProgramBar from "../../../Components/ProgramBar/program_bar";
import ShowAllPrograms from "../../../Components/ShowAllPrograms/show_all_programs";
import CookieNotice from "../../../Components/CookieNotice/cookie_notice";
import Download from "../../../Components/Download/download";
import Popup from "../../../Components/Popup/popup";
import AppButton from "../../../Components/AppButton/app_button";
import Premium from "../../../Components/Premium/premium";
import { Modal } from "antd";

/**
 * @typedef DashboardProps
 * @property {import("react").ReactNode} children
 * @property {string} [display]
 */
const Dashboard = /** @type {import("react").FC<DashboardProps>} */ (
  ({ children, display }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const getVerified = useGetVerified();
    const getLimitCheck = useLimitCheck();

    const uuid = useSelector(selectors.getAccountUuid);
    const getVouchers = useGetVouchers(uuid);

    const isAuthenticated = useSelector(selectors.getIsAuthenticated);
    const isLoggedOut = useSelector(selectors.getIsLoggedOut);
    const LimitCheck = useSelector(selectors.getLimitcheck);

    const [appDownloadPopup, setAppDownloadPopup] = useState(false);
    const tariffPlansPopup = useSelector(selectors.getIsGoPremiumPopupShown);

    // const premiumUrl = "https://www.paypal.com";

    // Drop Down
    const programName = useSelector(selectors.getSelectedProgramName);
    const [programSelected, setProgramSelected] = useState(null);

    console.log("programSelected Array:", programSelected);
    const program = useSelector(selector.getProgram);
    const industries = useSelector(selector.getIndustries);
    const selectedLp = useSelector(selectors.getSelectedLp);

    // console.log("selectedLp:", selectedLp);
    const hasAnyProgram = useSelector(selectors.getHasAnyProgram);
    const [selected_id, setSelectedID] = useState(selectedLp);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const openModal = (message) => {
      setModalMessage(message);
      setModalIsOpen(true);
    };

    const closeModal = () => {
      setModalIsOpen(false);
    };
    const getPrograms = useGetPrograms(uuid, null);

    useEffect(() => {
      if (!isAuthenticated && isLoggedOut) {
        getVerified();
      }
    }, [getVerified, isAuthenticated, isLoggedOut, navigate]);

    useEffect(() => {
      if (selectedLp && program.length > 0) {
        console.log("useEffect LP ~~~~~~~~~~~~~~~~~~~~", selectedLp);
        const selectedProgram = program.find(
          (program) => program.id === selectedLp,
        );
        if (selectedProgram) {
          console.log("IDDDDDD:", selectedProgram);
          setProgramSelected(selectedProgram);
        }
      } else {
        setProgramSelected(null); // Or set to a default program if needed
      }
    }, [program, selectedLp]);

    const sidebarRef = useRef(/** @type {HTMLDivElement} */ (null));

    useEffect(() => {
      /* In here we want to hide scrollbar from sidebar.
       * But we only need to do it in case the bar itself is huge and takes horizontal
       * space (Windows, macOS with mouse plugged and so on). To detect this case
       * measure width of the scrollbar using getScrollbarWidth()
       *
       * Do nothing if this is macOS without mouse of mobile device. Scrollbars in there
       * are nice, they don't take horizontal space and only appears when hovered/scrolled.
       */
      if (getScrollbarWidth() > 0) {
        if (sidebarRef.current) {
          sidebarRef.current.classList.add("hide-scrollbar");
        }
      }
    }, []);

    useEffect(() => {
      if (program && program.length > 0) {
        /*    if (!selectedLp) {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          console.log("selected id LP", selected_id);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          setSelectedID(selectedLp.id);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          setProgramSelected(programSelected || program[0]);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        console.log("selected id", selected_id); */
        let selectedProgram = program.find((prog) => prog.id === +selected_id);
        console.log("selected id ----------", selected_id);
        // sandeep setProgramSelected(selectedProgram);
        // setProgramSelected(selectedProgram || program[0]);
      } else if (!program && program?.length <= 0) {
        //TODO: CHECK THIS, TEST THIS CASE
        console.log("get programs - main page use effect");
        getPrograms();
        console.log("selected id", selectedLp);
        setSelectedID(selectedLp);
      }
    }, [getPrograms, program, selectedLp, selected_id]);

    let programSelected_industry;

    if (industries && industries.length > 0) {
      programSelected_industry = industries.find(
        (ind) => ind.id === (programSelected?.productTypeId ?? 0),
      );
    }

    const name = useSelector(selectors.getName);
    const email = useSelector(selectors.getEmail);
    const currentSubscription = useSelector(selectors.getAccountSubscription);
    const merchantUserRole = useSelector(selectors.getMerchantUserRole);
    const isSuperAdmin = useSelector(selectors.getSuperAdmin);
    const avatarUrl = `${process.env.REACT_APP_AVATAR_API_URL}${email}`;
    const isPremium = useSelector(selectors.getAccountSubscription);
    const showTierProgramsLimitReachedWarning =
      useShowTierLimitReachedWarning("program");
    console.log("is premium", isPremium);

    const [data, setData] = useState(
      /** @type {import("../../../types").Business} */ (null),
    );

    // State for dropdown visibility
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    // Handler for selecting a loyalty program
    /**
     *
     * @param {import("../../../types").Business} programSelected
     * @returns {Promise<void>}
     */
    const handleProgramSelect = async (programSelected) => {
      try {
        setSelectedID(programSelected.id);
        dispatch(actions.setSelectedLp(programSelected.id));
        dispatch(actions.setSelectedLpUuid(programSelected.uuid));
        console.log(programSelected.id);
        setIsDropdownVisible(false);
        setIsWorkspaceCollapsed(true);
        localStorage.setItem("editId", String(programSelected.id));
        console.log(programSelected.id);
        await getVouchers(programSelected.id);
        console.log("changed menu item", programSelected.id);
        setData(programSelected);

        if (display === constants.CREATE_VOUCHER_PAGE) {
          navigate(`/dashboard/${constants.MANAGE_VOUCHER_PAGE}`);
        } else if (display === constants.CREATE_LOYALTY_PAGE) {
          navigate(`/dashboard/${constants.MANAGE_PROGRAM_PAGE}`);
        }
      } catch (error) {
        console.error("Program selection failed:", error.message);
      }
      // setDisplay(5);
    };

    const performLogout = async () => {
      try {
        const result = await logoutService();
        if (result && result.success) {
          dispatch(actions.logout());

          // Wait for the 'persist/REHYDRATE' action to be processed
          await persist.dispatch({ type: "persist/REHYDRATE" });

          // Purge the persisted state
          await persist.purge();

          // Remove the 'persist:root' key
          //  localStorage.removeItem("persist:root");

          // Clear localStorage and Redux state
          // Only clear localStorage items that need to be cleared.
          ["Industries", "NewUser", "editId"].forEach((key) => {
            localStorage.removeItem(key);
          });

          // Navigate to login
          navigate("/");
        }
      } catch (error) {
        console.error("Logout failed:", error.message);
      }
    };

    const handleLogout = () => {
      handleActionNotification(
        performLogout,
        "Would you like to log out?",
        "Cancel",
        "Log out",
      );
    };

    const handleAddProgram = () => {
      if (LimitCheck.loyaltyProgramAlert.status) {
        showTierProgramsLimitReachedWarning();
      } else {
        setData(null);
        console.log("Page Number:", constants.CREATE_LOYALTY_PAGE);
        navigate(`/dashboard/${constants.CREATE_LOYALTY_PAGE}`);
        setIsDropdownVisible(false);
        setIsWorkspaceCollapsed(true);
      }
    };

    const [isWorkspaceCollapsed, setIsWorkspaceCollapsed] = useState(true);

    const workspaceItemsWhenCollapsed = isWorkspaceCollapsed ? 5 : Infinity;
    const workspaceShiftUp = isWorkspaceCollapsed ? 0 : "-89px";
    const workspace2ndColumnAt = 10;
    const workspace3rdColumnAt = 16;

    const dropdownColumnsFromProgramsAmount = (programs) => {
      if (programs < workspace2ndColumnAt) {
        return 1;
      }
      if (programs >= workspace2ndColumnAt && programs < workspace3rdColumnAt) {
        return 2;
      }
      return 3;
    };

    return (
      <>
        <div className="page">
          {
            <div className="sidebar" ref={sidebarRef}>
              <div className="sidebar__top">
                <button
                  className="sidebar__logo"
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  disabled={!hasAnyProgram}
                >
                  Meet
                </button>
                {/* <div className="sidebar__beta">Beta</div> */}
                {/* <div className="sidebar__version">{APP_VERSION}</div> */}
                <div
                  className="sidebar__programs"
                  onMouseEnter={() => setIsDropdownVisible(true)}
                  onMouseLeave={() => {
                    setIsDropdownVisible(false);
                    setIsWorkspaceCollapsed(true);
                  }}
                >
                  <button className="sidebar__current-program" type="button">
                    {/* Can be <a> or <button> */}
                    <img
                      className="sidebar__photo"
                      src={
                        programSelected
                          ? `${process.env.REACT_APP_CDN_URL}${programSelected.imageURL}.jpg`
                          : TopMenuIcon
                      }
                      width="60"
                      height="60"
                      alt=""
                    />
                  </button>
                  {isDropdownVisible && (
                    <div
                      className="sidebar__dropdown"
                      style={{
                        marginTop:
                          program && program.length > 5 ? workspaceShiftUp : 0,
                      }}
                    >
                      <div className="sidebar__drop-window">
                        <div
                          className={`workspace${
                            isWorkspaceCollapsed ? " workspace--collapsed" : ""
                          }`}
                        >
                          <div className="workspace__current">
                            {/* Selected program */}
                            {programSelected && (
                              <ProgramBar
                                selected
                                name={programSelected.name}
                                location={programSelected.physicalAddress}
                                category={programSelected_industry?.description}
                              />
                            )}
                          </div>
                          <div className="workspace__scroll css-scrollbar">
                            <div
                              className="workspace__list"
                              style={{
                                columns: dropdownColumnsFromProgramsAmount(
                                  program.length,
                                ),
                              }}
                            >
                              {/* All program except selected one */}
                              {program &&
                                program
                                  ?.slice(0, workspaceItemsWhenCollapsed)
                                  .map(
                                    (prop) =>
                                      prop !== programSelected && (
                                        <ProgramBar
                                          programUuid={prop.uuid}
                                          logo={`${process.env.REACT_APP_CDN_URL}${prop.imageURL}.jpg`}
                                          name={prop.name}
                                          location={
                                            prop.physicalAddress?.split(
                                              ",",
                                            )[2] ||
                                            prop.physicalAddress ||
                                            ""
                                          }
                                          // category={prop.description}
                                          onClick={() =>
                                            handleProgramSelect(prop)
                                          }
                                          key={prop.id} // Add a unique key for each item
                                        />
                                      ),
                                  )}
                            </div>
                          </div>
                          {/* Show all Loyalty Programs */}
                          {program.length > workspaceItemsWhenCollapsed && (
                            <div className="workspace__show-all">
                              <ShowAllPrograms
                                horizontal={
                                  program.length >= workspace2ndColumnAt
                                }
                                onClick={() => {
                                  setIsWorkspaceCollapsed(
                                    !isWorkspaceCollapsed,
                                  );
                                }}
                              >
                                {program.length - workspaceItemsWhenCollapsed}{" "}
                                more
                              </ShowAllPrograms>
                            </div>
                          )}
                          <div className="workspace__add">
                            {/* Create Loyalty Program */}
                            <AddProgramBar
                              onClick={handleAddProgram}
                              disabledButClickable={
                                LimitCheck?.loyaltyProgramAlert?.status
                              }
                            >
                              Create a loyalty program
                            </AddProgramBar>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="sidebar__middle">
                <button
                  className={`sidebar__link${
                    display === constants.OVERVIEW_PAGE
                      ? " sidebar__link--current"
                      : ""
                  }`}
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  disabled={!hasAnyProgram}
                >
                  <IconHome className="sidebar__illustration" />
                  <span className="visuallyhidden">Home</span>
                </button>
                <button
                  className={`sidebar__link${
                    display === constants.MANAGE_VOUCHER_PAGE ||
                    display === constants.CREATE_VOUCHER_PAGE
                      ? " sidebar__link--current"
                      : ""
                  }`}
                  onClick={() => {
                    if (selectedLp) {
                      navigate(`/dashboard/${constants.MANAGE_VOUCHER_PAGE}`);
                    } else {
                      notification.warning({
                        message: "Select the program first",
                      });
                    }
                  }}
                  disabled={!hasAnyProgram}
                >
                  <IconVoucher className="sidebar__illustration" />
                  <span className="visuallyhidden">Voucher</span>
                </button>
                <button
                  className={`sidebar__link${
                    display === constants.LOOKER_PAGE
                      ? " sidebar__link--current"
                      : ""
                  }`}
                  onClick={() => {
                    navigate(`/dashboard/${constants.LOOKER_PAGE}`);
                  }}
                  disabled={!hasAnyProgram}
                >
                  <IconAnalytics className="sidebar__illustration" />
                  <span className="visuallyhidden">Analytics</span>
                </button>
                <button
                  className={`sidebar__link${
                    display === constants.MANAGE_PROGRAM_PAGE ||
                    display === constants.CREATE_LOYALTY_PAGE
                      ? " sidebar__link--current"
                      : ""
                  }`}
                  onClick={() => {
                    if (hasAnyProgram) {
                      navigate(`/dashboard/${constants.MANAGE_PROGRAM_PAGE}`);
                    } else {
                      navigate(`/dashboard/${constants.CREATE_LOYALTY_PAGE}`);
                    }
                  }}
                >
                  <IconEdit className="sidebar__illustration" />
                  <span className="visuallyhidden">Edit</span>
                </button>
                {merchantUserRole !== "USER" && isPremium !== 0 && (
                  <button
                    className={`sidebar__link${
                      display === constants.USER_MANAGEMENT_PAGE
                        ? " sidebar__link--current"
                        : ""
                    }`}
                    onClick={() => {
                      navigate(`/dashboard/${constants.USER_MANAGEMENT_PAGE}`);
                    }}
                    disabled={!hasAnyProgram}
                  >
                    <IconUserManagement className="sidebar__illustration" />
                    <span className="visuallyhidden">Edit</span>
                  </button>
                )}
                <button
                  className="sidebar__link"
                  onClick={() => {
                    setAppDownloadPopup(true);
                  }}
                >
                  <IconAppDownload className="sidebar__illustration" />
                  <span className="visuallyhidden">Edit</span>
                </button>
              </div>
              <div className="sidebar__bottom">
                <a className="sidebar__action" href="#">
                  <IconHelp className="sidebar__icon" />
                  <span className="visuallyhidden">Help</span>
                </a>
                <a
                  className="sidebar__action"
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <IconLogout className="sidebar__icon" />
                  <span className="visuallyhidden">Logout</span>
                </a>
              </div>
            </div>
          }

          <div className="header">
            {currentSubscription === 0 && (
              <>
                <div className="header__premium">
                  <AppButton
                    variant="paypal"
                    asLink
                    target="_blank"
                    onClick={() => {
                      dispatch(actions.setIsGoPremiumPopupShown(true));
                    }}
                  >
                    Go Premium
                  </AppButton>
                </div>
                {/* <div className="header__plans">
                <AppButton
                  variant="secondary"
                  leftIcon={ModalIcon}
                  onClick={premiumUrl}
                >
                  Learn More
                </AppButton>

              </div>{" "} */}
              </>
            )}

            <div className="header__user">
              <User
                name={name}
                email={email}
                avatarUrl={avatarUrl}
                onLogout={handleLogout}
                isSuperAdmin={isSuperAdmin}
              />
              {currentSubscription !== 0 && (
                <PremiumIndicator className="PremiumIndicator" />
              )}
            </div>
          </div>

          <main className="main">{children}</main>

          <CookieNotice />
        </div>

        <div className="scrollbar-width-check">
          <div className="scrollbar-width-check__inner"></div>
        </div>

        {appDownloadPopup && (
          <Popup
            onCloseClick={() => {
              setAppDownloadPopup(false);
            }}
          >
            <Download />
          </Popup>
        )}

        {tariffPlansPopup && (
          <Popup
            roundedAndFramed
            onCloseClick={() => {
              dispatch(actions.setIsGoPremiumPopupShown(false));
            }}
          >
            <Premium
              freeOnClickFunction={() => {
                dispatch(actions.setIsGoPremiumPopupShown(false));
              }}
              business={data}
              payment={(data) => {
                dispatch(actions.setIsGoPremiumPopupShown(false));
                openModal("Payment Successful");
                console.log("Subscription Data:", data);
                dispatch(actions.setCurrentSubscription(data));
                getLimitCheck();
              }}
            />
          </Popup>
        )}
        <Modal
          title="Payment Status"
          visible={modalIsOpen}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{modalMessage}</p>
        </Modal>
      </>
    );
  }
);

export default Dashboard;
