import React, { useState } from "react";
import "./paypal_annual_subscription_button.css";
import useUpdatePaymentApproval from "../../Hooks/updatePaymentApproval";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { Modal } from "antd";

/**
 * @param {object} props
 * @param {()=>void} props.payment
 * @param {import("../../types").Business} props.data
 * @returns {import("react").ReactNode}
 */
function PaypalAnnualSubscriptionButton({ data, payment }) {
  const updatedPaymentApproval = useUpdatePaymentApproval();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const openModal = (message) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <div className="paypal-button-container">
      <div className="paypal-button-wrapper">
        <PayPalButtons
          style={{
            shape: "rect",
            color: "black",
            layout: "horizontal",
            label: "subscribe",
            tagline: false,
            height: 40,
          }}
          createSubscription={(data, actions) => {
            return actions.subscription.create({
              plan_id: String(process.env.REACT_APP_PAYPAL_ANNUAL_PLAN_ID),
            });
          }}
          onApprove={async (data, actions) => {
            alert(`Subscription ID: ${data.subscriptionID}`);
            await updatedPaymentApproval({
              data,
              success: true,
              planId: String(process.env.REACT_APP_PAYPAL_ANNUAL_PLAN_ID),
            });
            payment(2);
            openModal("Payment Successful");
          }}
          onError={async (err) => {
            console.error("Err", err);
            await updatedPaymentApproval({
              data,
              success: false,
              planId: String(process.env.REACT_APP_PAYPAL_ANNUAL_PLAN_ID),
            });
            openModal("Payment Failed");
          }}
          onCancel={async (canc) => {
            console.log("Canceled", canc);
            await updatedPaymentApproval({
              data,
              success: false,
              planId: String(process.env.REACT_APP_PAYPAL_ANNUAL_PLAN_ID),
            });
            openModal("Payment Cancelled");
          }}
        />
        <Modal
          title="Payment Status"
          visible={modalIsOpen}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{modalMessage}</p>
        </Modal>
      </div>
    </div>
  );
}

export default PaypalAnnualSubscriptionButton;
